import { OptionalFiltersParam } from '@/composables/types/useFilter'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'

import { BasicEntity } from '@/api/types/misc'
import {
  UseGetInventoryItems,
  UseGetInventoryItemsBasic,
  UseCreateInventoryItem,
  UseDeleteInventoryItem,
  UseUpdateInventoryItem,
  InventoryItemInput,
  InventoryItemOutput,
  InventoryItemId,
  UseExportInventoryItems,
} from './types/inventoryItem'

const VERSION = 'v1'
const RESOURCE = 'inventory-items'

function useGetInventoryItems(): UseGetInventoryItems {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetInventoryItems['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetInventoryItemsBasic(): UseGetInventoryItemsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateInventoryItem(): UseCreateInventoryItem {
  const axios = useAxios<PromiseType<ReturnType<UseCreateInventoryItem['createInventoryItem']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createInventoryItem(data: InventoryItemInput): Promise<InventoryItemOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createInventoryItem,
  }
}

function useUpdateInventoryItem(): UseUpdateInventoryItem {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateInventoryItem['updateInventoryItem']>>>({ method: 'PUT' })

  function updateInventoryItem(id: InventoryItemId, data: InventoryItemOutput): Promise<InventoryItemOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateInventoryItem,
  }
}

function useDeleteInventoryItem(): UseDeleteInventoryItem {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteInventoryItem['deleteInventoryItem']>>>({ method: 'DELETE' })

  function deleteInventoryItem(id: InventoryItemId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteInventoryItem,
  }
}

function useExportInventoryItems(): UseExportInventoryItems {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportInventoryItems(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportInventoryItems,
  }
}

export {
  useGetInventoryItems,
  useGetInventoryItemsBasic,
  useCreateInventoryItem,
  useUpdateInventoryItem,
  useDeleteInventoryItem,
  useExportInventoryItems,
}
