
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isRequired } from '@/utils/validation'
import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetUsersBasic } from '@/api/user'
import { useGetInventoryItems, useGetInventoryItemsBasic } from '@/api/inventoryItem'
import { useCreateEquipment, useUpdateEquipment } from '@/api/equipment'
import { useGetPersonsBasic } from '@/api/person'
import { useGetContractsBasic } from '@/api/contract'

import { EquipmentOutput, EquipmentInput } from '@/api/types/equipment'
import { isOfType } from '@/utils/types/isOfType'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'AddEditEquipmentDialog',
  components: {
    CommonAddEditDialog,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    equipmentToEdit: {
      type: Object as PropType<EquipmentOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.equipmentToEdit))

    const { addNotification } = useNotify()

    const CURRENT_PERSON_ID = Number(root.$route.params.id)

    const { exec: getUsersBasic, data: users, isLoading: isLoadingUsersBasic } = useGetUsersBasic()

    if (hasSufficientRights(Rights.BASIC_READ)) {
      getUsersBasic()
    }
    const {
      exec: getInventoryItemsBasic,
      data: inventoryItemsBasic,
      isLoading: isLoadingInventoryItemsBasic,
    } = useGetInventoryItemsBasic()
    getInventoryItemsBasic({ params: { available: true } })

    const { exec: getPersonsBasic, data: persons, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()
    getPersonsBasic()

    const { exec: getContractsBasic, data: contracts, isLoading: isLoadingContractsBasic } = useGetContractsBasic()
    getContractsBasic()

    const nameRequiredRule = (value: string) =>
      isRequired(value, root.$t('persons.profile.tabMenu.equipment.form.name') as string)

    const FORM_FIELDS = ref<FormField[]>([
      ...(!CURRENT_PERSON_ID
        ? [
            {
              value: 'person',
              fieldType: FORM_FIELDS_ENUM.DROPDOWN,
              items: computed(() => persons.value ?? []),
              isLoading: computed(() => isLoadingPersonsBasic.value),
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(value, root.$t('persons.profile.tabMenu.equipment.form.person') as string),
              ],
              dropdownTextProp: 'name',
            },
          ]
        : []),
      {
        value: 'name',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        isDisabled: false,
        rules: [nameRequiredRule],
      },
      {
        value: 'type',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        isDisabled: false,
        rules: [],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        isDisabled: false,
        rules: [],
      },
      {
        value: 'serialNumber',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        isDisabled: false,
        rules: [],
      },
      {
        value: 'inventoryItem',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => inventoryItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingInventoryItemsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'issuedOn',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'issuedBy',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'plannedReturn',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'actualReturn',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'actualReturnBy',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'contract',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => contracts.value ?? []),
        isLoading: computed(() => isLoadingContractsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
    ])

    const form = ref<EquipmentInput | EquipmentOutput>(
      isEditMode.value ? cloneDeep(props.equipmentToEdit) : ({} as EquipmentInput)
    )

    const FORM_FIELD_NAMES_TO_RESET: readonly string[] = ['name', 'type', 'description', 'serialNumber']
    const formFieldsToReset = FORM_FIELDS.value.filter((field) => FORM_FIELD_NAMES_TO_RESET.includes(field.value))

    const { createEquipment, isLoading: isLoadingCreateEquipment } = useCreateEquipment()

    async function onAdd(): Promise<void> {
      if (!isOfType<EquipmentOutput>(form.value, 'id')) {
        try {
          if (form.value['inventoryItem']) {
            formFieldsToReset.forEach((field) => {
              form.value[field.value] = ''
            })
          }

          const updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS.value as FormField[], form.value)

          if (CURRENT_PERSON_ID) {
            updatedForm.person = CURRENT_PERSON_ID
          }

          await createEquipment(updatedForm)

          addNotification({
            text: root.$t('persons.profile.tabMenu.equipment.add.success') as string,
            type: 'success',
            timeout: 3000,
          })

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateEquipment, isLoading: isLoadingUpdateEquipment } = useUpdateEquipment()

    async function onEdit(): Promise<void> {
      if (isOfType<EquipmentOutput>(form.value, 'id')) {
        try {
          let updatedForm = mapBasicEntityToIdWithForm<EquipmentOutput>(form.value)

          updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS.value as FormField[], updatedForm)

          await updateEquipment(updatedForm.id, updatedForm)

          addNotification({
            text: root.$t('persons.profile.tabMenu.equipment.update.success') as string,
            type: 'success',
            timeout: 3000,
          })

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditEquipment = computed(() => isLoadingCreateEquipment.value || isLoadingUpdateEquipment.value)

    function close(): void {
      emit('reload-equipment-list')

      emit('close')
    }

    watch(
      () => props.equipmentToEdit,
      () => {
        if (props.equipmentToEdit) {
          form.value = props.equipmentToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS.value as FormField[], form.value)
        }
      },
      { immediate: true }
    )

    const { exec: getInventoryItems, data: inventoryItems } = useGetInventoryItems()
    getInventoryItems()

    watch(
      form,
      () => {
        if (form.value['inventoryItem']) {
          formFieldsToReset.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            FORM_FIELDS.value.find((formField) => formField.value === field.value)!.isDisabled = true
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            FORM_FIELDS.value.find((formField) => formField.value)!.rules = []
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            FORM_FIELDS.value.find((formField) => formField.value === 'name')!.isRequired = false

            if (!props.equipmentToEdit)
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              form.value[field.value] = inventoryItems.value.find((item) => item.id === form.value['inventoryItem'])![
                field.value
              ]
          })
        } else {
          formFieldsToReset.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            FORM_FIELDS.value.find((formField) => formField.value === field.value)!.isDisabled = false
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            FORM_FIELDS.value.find((formField) => formField.value === 'name')!.rules = [nameRequiredRule]
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            FORM_FIELDS.value.find((formField) => formField.value === 'name')!.isRequired = true
          })
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    watch(
      [
        () => form.value['name'],
        () => form.value['type'],
        () => form.value['description'],
        () => form.value['serialNumber'],
      ],
      () => {
        if (form.value['name'] || form.value['type'] || form.value['description'] || form.value['serialNumber']) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          FORM_FIELDS.value.find((formField) => formField.value === 'inventoryItem')!.isDisabled = true
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          FORM_FIELDS.value.find((formField) => formField.value === 'inventoryItem')!.isDisabled = false
        }
      },
      {
        immediate: true,
      }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditEquipment,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
